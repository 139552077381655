import React from "react"
import { graphql } from "gatsby"
import moment from "moment";
import _ from "lodash"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import FormBreadcrumb from "../components/Breadcrumbs/FormBreadcrumb"
import JobApply from "../components/forms/job-apply"
import ErrorPage from '../pages/404'
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../scss/custom.scss"
import "../scss/career-custom.scss"
import "../components/FormStep/FormStep.scss"
// import "../components/FormStep/ValuationForm.scss"


const JobFormTemplate = props => {
  var job = null;
  if (props.pageContext.job_details) {
    job = props.pageContext.job_details;
  }
  var showPage = true
  if (job.vacancy_closing_date && (moment(job.vacancy_closing_date).isAfter(moment().format('YYYY-MM-DD'), 'day') == false)) {
    showPage = false
  }
  return (
    <>
      {showPage ? <>
        <Header />
        <div className="wrapper vacancy-details">
          <SEO title="Arun Estates | Apply" description="Arun Estates | Apply" />
          <FormBreadcrumb {...job} />
          <JobApply job={job} />
        </div>
        <Footer /> </> : <ErrorPage />}
    </>

  )
}
export default JobFormTemplate
