import { Link } from "gatsby"
import React, { useState, useEffect, createRef } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { navigate } from "@reach/router";
import Select from 'react-select';
import _ from "lodash"
import $ from "jquery"
import parse from "html-react-parser";
import loadable from '@loadable/component'
import * as qs from "query-string"
import { postFormData } from "./api/Api"
import UserAgent from 'user-agents';
import { getIpAddress } from "../common/site/functions"
import axios from "axios"
import { getAllCookie } from "../common/site/cookie";
const LoqateAddress = loadable(() => import('./elements/address-search-instant'))
// import "./FormStep.scss"
// markup
const JobApply = (props) => {
  const userAgent = new UserAgent();
  const myRef = React.createRef();


  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  var en_id = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase()


  const originOptions = [
    { value: "", label: 'Start typing...' },
    { value: 'Afro Caribbean', label: 'Afro Caribbean' },
    { value: 'Asian', label: 'Asian' },
    { value: 'Bangladeshi', label: 'Bangladeshi' },
    { value: 'Black African', label: 'Black African' },
    { value: 'Black American', label: 'Black American' },
    { value: 'Black British', label: 'Black British' },
    { value: 'Black Caribbean', label: 'Black Caribbean' },
    { value: 'Black Other', label: 'Black Other' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Indian', label: 'Indian' },
    { value: 'Iraqi', label: 'Iraqi' },
    { value: 'Mixed', label: 'Mixed' },
    { value: 'Nepalese', label: 'Nepalese' },
    { value: 'Pakistani', label: 'Pakistani' },
    { value: 'Sudanese', label: 'Sudanese' },
    { value: 'Thai', label: 'Thai' },
    // { value: 'Unknown', label: 'Unknown' },
    { value: 'White American', label: 'White American' },
    { value: 'White Aus/NZ', label: 'White Aus/NZ' },
    { value: 'White British', label: 'White British' },
    { value: 'White Caribbean', label: 'White Caribbean' },
    { value: 'White European', label: 'White European' },
    { value: 'White Other', label: 'White Other' },
    { value: 'Prefer not to say', label: 'Prefer not to say' }
  ]
  const drivingLicenseptions = [
    { value: null, label: 'Please choose...' },
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const nameTitleptions = [
    { value: null, label: 'Please choose...' },
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' }
  ]
  var formFields = {
    firstname: "",
    lastname: "",
    email: "",
    contactPhoneNo: "",
    secondaryContactNo: "",
    nationalInsuranceNo: "",
    ethnicOrigin: "",
    drivingLicense: false,
    title: false,
    address: "",
    in_address: "",
    motivation: "",
    recruitment_agent: false,
    uploadCV: "",
    coverLetter: "",
    portfolio: "",
    form_type_id: 6,
    form_name: "Job Application",
    recruiterName: "",
    recruiterFirm: "",
    beforeCandidate: "",
    submittedCV: "",
    videoask: false,
    enquiry_id: en_id,
    vacancy_id: props.job?.vacancy_id,


  }
  var validationFieldsStep3 = [];
  var validationFieldsStep4 = [];
  if (!_.isEmpty(props.job.vacancy_questions)) {
    props.job.vacancy_questions.map((ques, k) => {
      validationFieldsStep3.push(`vacancy_questions_${k + 1}`)
      _.assignIn(formFields, { [`vacancy_questions_${k + 1}`]: '' });
    })
  }
  if (!_.isEmpty(props.job.vacancy_multichoices)) {
    props.job.vacancy_multichoices.map((ques, k) => {
      validationFieldsStep4.push(`vacancy_multichoices_${k + 1}`)
      _.assignIn(formFields, { [`vacancy_multichoices_${k + 1}`]: '' });
    })
  }

  const [ipAddress, setIpAddress] = useState("");
  const [step, setStep] = useState(1);
  const [formId, setFormId] = useState("");

  const formTitle = [
    "So to begin with, we'll just need some details.",
    "Thanks Chris. Now we’d like to hear why this job interests you?",
    "Now tell us a little more about yourself.",
    "Nearly there... now for a final few quick fire questions",
    "Nearly there... Now for a few more documents ",
    "Leave your video here",
    "Thank you for your application."
  ];
  const [formState, setFormState] = useState(formFields);
  const [errors, setErrors] = useState({});
  const [fileSupport, setFileSupport] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [originselectedOption, setOriginselectedOption] = useState({ value: null, label: 'Start typing...' })
  const [drivingLicenseselectedOption, setDrivingLicenseselectedOption] = useState({ value: null, label: 'Please choose...' })
  const [nameTitleselectedOption, setNameTitleselectedOption] = useState({ value: null, label: 'Please choose...' })
  const [beforeCandidateSelectedOption, setBeforeCandidateselectedOption] = useState({ value: null, label: 'Please choose...' })
  const [submittedCVSelectedOption, setSubmittedCVselectedOption] = useState({ value: null, label: 'Please choose...' })
  const [uploadCV, setUploadCV] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [enquiryId, setEnquiryId] = useState(en_id);
  const [portfolio, setPortfolio] = useState("");
  const [addressselect, setAddressSelect] = useState("");
  const [videoaskstate, setVideoAskState] = useState(false);
  const [minValidation, setMinValidation] = useState(false);
  const [wordcount, setWordCount] = useState("");
  const [stepnumber, setStepNumber] = useState("1");
  const uploadCVRef = createRef(null);
  const coverLetterRef = createRef(null);
  const portfolioRef = createRef(null);

  var originhandleChange = originselectedOption => {
    setFormState({
      ...formState,
      ethnicOrigin: originselectedOption.value
    });
    setOriginselectedOption(originselectedOption);
  };
  var licensehandleChange = drivingLicenseselectedOption => {
    setFormState({
      ...formState,
      drivingLicense: drivingLicenseselectedOption.value
    });
    setErrors({ ...errors, drivingLicense: false });
    setDrivingLicenseselectedOption(drivingLicenseselectedOption);
  };
  var namehandleChange = nameTitleselectedOption => {
    setFormState({
      ...formState,
      title: nameTitleselectedOption.value
    });
    setErrors({ ...errors, title: false });
    setNameTitleselectedOption(nameTitleselectedOption);
  };
  var beforeCandidatehandleChange = selected => {
    setFormState({
      ...formState,
      beforeCandidate: selected.value
    });
    setErrors({ ...errors, beforeCandidate: false });
    setBeforeCandidateselectedOption(selected);
  };
  var submittedCVhandleChange = selected => {
    setFormState({
      ...formState,
      submittedCV: selected.value
    });
    setErrors({ ...errors, submittedCV: false });
    setSubmittedCVselectedOption(selected);
  };

  const handelChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
    setErrors({
      ...errors,
      [e.target.name]: false
    });
  }
  const handleValidation = (fields, step) => {
    var error = {}
    var isValid = true;
    // console.log(fields)
    var validationFields = ['title', 'firstname', 'lastname', 'email', 'contactPhoneNo', 'drivingLicense'];
    if (step === 2) {
      if (fields.recruitment_agent) {
        validationFields = ['motivation', 'recruiterName', 'recruiterFirm', 'beforeCandidate', 'uploadCV', 'submittedCV']
      } else {
        validationFields = ['motivation'];
      }

    } else if (step === 3) {
      validationFields = validationFieldsStep3;
      setStepNumber("3")
    } else if (step === 4) {
      validationFields = validationFieldsStep4;
      setStepNumber("4")
    } else if (step === 5) {
      validationFields = ['uploadCV'];
      setStepNumber("5")
    } else if (step === 6) {
      validationFields = [];
      setStepNumber("6")
    }

    _.forEach(validationFields, (field, k) => {


      if (_.isEmpty(fields[field]) && step !== 5) {
        if (step == 2 && fields.recruitment_agent) {
          if (_.isEmpty(fields[field]?.name)) {
            error[field] = true;
            isValid = false
          }
        } else {
          error[field] = true;
          isValid = false
        }
      } else {
        if (field === "email" && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fields[field]))) {
          setStepNumber("1")
          error[field] = true;
          isValid = false
        } else if (field === "contactPhoneNo" && fields[field].length <= 8) {
          setStepNumber("1")
          error[field] = true;
          isValid = false
        } else if (step === 2 && fields[field]?.replace(/ +(?= )/g, '').split(" ").length > 300) {

          error[field] = true;
          isValid = false
          setStepNumber("2")
          setWordCount(fields[field].replace(/ +(?= )/g, '').split(" ").length)
        } else if (step === 3 && fields[field].replace(/ +(?= )/g, '').split(" ").length > 300) {
          setStepNumber("3")
          error[field] = true;
          isValid = false
          if (fields[field].length > 0) {
            setMinValidation(true)
            setWordCount(fields[field].replace(/ +(?= )/g, '').split(" ").length)
          } else {
            setMinValidation(false)
            setWordCount(fields[field].replace(/ +(?= )/g, '').split(" ").length)
          }
        } else if (step === 5 && _.isEmpty(fields[field]?.name)) {
          setStepNumber("5")
          error[field] = true;
          isValid = false
        } else {
          error[field] = false;
        }
      }
    })

    if (!isValid) {
      $('html, body').animate({
        scrollTop: $("#jobform").offset().top
      }, 100);
      setErrors(error)
      return false;

    }
    if (step === 5 && (errors['uploadCV'] || errors['portfolio'] || errors['coverLetter'])) {
      setStepNumber("5")
      return false
    }
    return true
  }

  const scrollToTargetAdjusted = () => {
    var element = document.getElementById("jobform");
    var headerOffset = 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  const AEstaffProspectus = "https://arunestatesemailstorage.blob.core.windows.net/documents/arun-estates-staff-prospectus-2023.pdf"
  const DAstaffProspectus = "https://arunestatesemailstorage.blob.core.windows.net/documents/douglas-allen-staff-prospectus-2023.pdf"

  const handleSubmit = (event, step) => {
    event.preventDefault();
    if (step === 1) {
      setStep(7)
      var in_address = document.getElementById('in_address').value;
      // console.log("ccc",in_address)
      var address = {
        "stno": document.getElementById('instant_stno').value,
        "street": document.getElementById('instant_street').value,
        "district": document.getElementById('instant_district').value,
        "post_town": document.getElementById('instant_post_town').value,
        "county": document.getElementById('instant_county').value,
        "postcode": document.getElementById('instant_postcode').value
      }

      setAddressSelect(address)

      setFormState({
        ...formState,
        address: address,
        in_address: in_address
      });

      if (_.isEmpty(in_address)) {
        document.getElementById('in_address').classList.add("field-error");
      } else {
        document.getElementById('in_address').classList.remove("field-error");
      }
    }
    if (handleValidation(formState, step)) {
      // if (step === 5 || formState.recruitment_agent) {
      if (step === 2) {
        setContactName(formState.firstname + ' ' + formState.lastname);
        setContactEmail(formState.email);
      }
      if (step === 6) {
        setErrorFlag(false)
        let formData = new FormData();

        if ((formState.uploadCV != "" && formState.uploadCV != null)) {
          formData.append('files.uploadCV', formState.uploadCV, formState.uploadCV.name);
        }
        if ((formState.coverLetter != "" && formState.coverLetter != null)) {
          formData.append('files.coverLetter', formState.coverLetter, formState.coverLetter.name);
        }
        if ((formState.portfolio != "" && formState.portfolio != null)) {
          formData.append('files.portfolio', formState.portfolio, formState.portfolio.name);
        }

        const url = typeof window !== 'undefined' ? window.location.href : ''
        var gtm_client_id = '';
        var user_agent = '';
        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }
        var cookieData = getAllCookie();
        formState['form_page'] = url;
        formState['brand_id'] = process.env.GATSBY_STRAPI_BRAND_ID;
        formState['surname'] = formState.lastname;
        formState['drivingLicense'] = formState.drivingLicense == "Yes" ? true : false;
        formState['referrer'] = url;
        formState['utm_source'] = cookieData.utm_source;
        formState['utm_medium'] = cookieData.utm_medium;
        formState['utm_campaign'] = cookieData.utm_campaign;
        formState['utm_term'] = cookieData.utm_term;
        formState['utm_content'] = cookieData.utm_content;
        formState['sourcecode'] = cookieData.sourcecode ? cookieData.sourcecode : cookieData.source_code ? cookieData.source_code : 'CV';
        formState['google_analytics_client_id'] = gtm_client_id;
        formState['user_agent'] = user_agent;
        formState['ip_address'] = ipAddress;
        formState['ni_number'] = formState.nationalInsuranceNo;
        formState['recruitment_agency'] = formState.recruiterName && formState.recruiterFirm ? formState.recruiterName + ", " +formState.recruiterFirm : "";
        formState['phone_1'] = formState.contactPhoneNo;
        formState['phone_2'] = formState.secondaryContactNo;
        formState['videoaskStatus'] = formState.videoask;
        formState['videoaskStatusnew'] = formState.videoask;
        formState['message'] = formState.motivation;
        if (props.job.videoask_video_id === "null" || props.job.videoask_video_id == "" || props.job.videoask_video_id == null) {
          formState['videoaskStatus'] = true;
        }

        delete formState["uploadCV"];
        delete formState["coverLetter"];
        delete formState["portfolio"];
        delete formState["contactPhoneNo"];
        delete formState["recruiterName"];
        delete formState["secondaryContactNo"];
        delete formState["address"]
        delete formState["motivation"]
        formState['extra'] = JSON.stringify(formState);
        formData.append('extra', JSON.stringify(formState));


        formState['address'] = addressselect;
        formData.append('data', JSON.stringify(formState));
        setContactName(formState.firstname + ' ' + formState.lastname);
        setContactEmail(formState.email);
        scrollToTargetAdjusted()
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form- Apply for job',
          'formId': 'form- Apply for job',
          'formName': 'Apply for job',
          'formLabel': 'Apply for job'
        });

        postFormData(formData).then(async apiRes => {
          // if (props.job.videoask_video_id == "null") {
          setStep(7)
          sendMail();
          // } else {
          //   setStep(6)
          // }
          scrollToTargetAdjusted()

          if (apiRes.success === true) {
            formState.recruitment_agent = false
            setFormId(apiRes.form.id)
          }
        })
      } else {
        setErrorFlag(false)
        if (formState.recruitment_agent) {
          if(step === 1) {
            setStep(2)
          } else {
            setStep(6)
          }
        }
        else {
          setStep(step + 1)
        }
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    } else {
      setErrorFlag(true)
    }

  }

  // const sumbitVideoAsk = (id) => {
  //   axios.post(`${process.env.GATSBY_STRAPI_FORM_URL}/videoask`, {
  //     formid: id,
  //     videoaskStatus: true
  //   }, {
  //     headers: {
  //       "Authorization": `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //     }
  //   }).then((result) => {
  //     console.log(result)
  //   }).catch((err) => {
  //     console.log(err)
  //   });
  // }

  const errClass = (field) => {
    if (errors[field]) {
      return 'field-error';
    }
    return ''
  }
  const charLength = (field) => {
    if (!_.isEmpty(formState[field])) {
      return formState[field].replace(/ +(?= )/g, '').split(' ').length
    }
    return 0;
  }

  const sendMail = async () => {
    var formvalues = {};
    formvalues['email'] = formState.email;
    formvalues['name'] = formState.title + '' + formState.firstname + '' + formState.lastname;
    formvalues['job_role'] = props.job.vacancy_type;
    formvalues['email_temp_user'] = 'careers_user';
    formvalues['email_temp_admin'] = 'careers_admin';
    formvalues['email_subject_user'] = 'We’ve received your application';
    formvalues['brand_name'] = 'Arun Estates'
    formvalues['first_name'] = formState.firstname
    formvalues['staff_prospectus_url'] = AEstaffProspectus;
    formvalues['job_title'] = 'Thanks for applying for the position of ' + props.job?.vacancy_title + ',';
    // formvalues['job_title'] = props.job?.vacancy_title;
    formvalues['consultant_name'] = props.job?.consultant_title + ' ' + props.job?.consultant_name;

    if (props.job?.brand_id === 'wk') {
      formvalues['brand_name'] = 'Wards'
    } else if (props.job?.brand_id === 'cw') {
      formvalues['brand_name'] = 'Cubitt & West'
    } else if (props.job?.brand_id === 'da') {
      formvalues['brand_name'] = 'Douglas Allen'
      formvalues['staff_prospectus_url'] = DAstaffProspectus
    } else if (props.job?.brand_id === 'pt') {
      formvalues['brand_name'] = 'Pittis'
    } else if (props.job?.brand_id === 'mmd') {
      formvalues['brand_name'] = 'Mortgage Matters Direct'
    }
    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
      method: `POST`,
      mode: "no-cors",
      headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify(formvalues),
    })
  }
  const stepRender = (step) => {
    var iconCount = 5;
    var stepHTML = '';
    var runStep = 7;
    if (props.job.videoask_video_id == "null") {
      runStep = 6
    }
    if (step < runStep) {
      stepHTML = '<div class="circle-wrapper">'
      for (var i = 0; i < iconCount; i++) {
        if ((i + 1) >= step) {
          stepHTML += `<span class="circle count ${(i + 1 == step) ? 'active' : ''}"><span className="text">${i + 1}</span></span>`
        } else {
          stepHTML += '<span class="circle"><i class="icon-check"></i></span>'
        }
      }
      stepHTML += '</div>';
    } else {
      stepHTML += '<div class="circle-wrapper thankyou"><span class="circle"><i class="icon-check"></i></span></div>'
    }

    stepHTML += '</div>';
    return stepHTML;
  }

  const styles = {
    control: styles => ({ ...styles, color: '#4D676F', border: "1px solid #dbe8ec", minHeight: '56px', paddingLeft: '1rem', marginBottom: '1rem', borderRadius: '8px' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#4D676F',
      }
    },
    singleValue: styles => ({ ...styles, height: '20px' }),
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file 
    var d = document.getElementById(event.target.id);
    // alert(event.target.id);
    // $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));

    if ((fileItem == '') || (fileItem == null) || (fileItem == undefined)) {
      $(`.${event.target.id}_lable`).addClass('field-error');
      $(`.${event.target.id}_lable`).removeClass('fileadded');
      setErrors({ ...errors, [event.target.name]: true });
    } else {
      $(`.${event.target.id}_lable`).removeClass('field-error');
      $(`.${event.target.id}_lable`).addClass('fileadded');
      setErrors({ ...errors, [event.target.name]: false });
    }
    setFormState({
      ...formState,
      [event.target.name]: fileItem
    });
    var fieldName = event.target.name;
    var fieldId = event.target.id;
    var blob = event.target.files[0]; // See step 1 above
    var fileReader = new FileReader();
    fileReader.onloadend = function (event) {
      var arr = (new Uint8Array(event.target.result)).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (header === "25504446" || header === "504b34" || header == "89504e47" || header == "ffd8ffe0" || header == "d0cf11e0") { //PDF 
        setErrors({ ...errors, [fieldName]: false });
        $(`.${fieldId}_lable`).removeClass('field-error');
        $(`.${fieldId}_lable`).addClass('fileadded');
        setFileSupport(true)
      } else {
        setErrors({ ...errors, [fieldName]: true });
        $(`.${fieldId}_lable`).addClass('field-error');
        $(`.${fieldId}_lable`).removeClass('fileadded');
        setFileSupport(false)
      }

    };
    fileReader.readAsArrayBuffer(blob)



    // if (event.target.name === "upload_cv") {
    //   setUploadCV(fileItem) 
    // }
    // if (event.target.name === "cover_letter") {
    //   setCoverLetter(fileItem)
    // }
    // if (event.target.name === "portfolio") {
    //   setPortfolio(fileItem)
    // }
  };
  useEffect(() => {
    getIpAddress((ip) => {
      setIpAddress(ip)
    })

    const isVideoaskMessage = message =>
      message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith("videoask_")

    window.addEventListener("message", message => {
      if (!isVideoaskMessage(message)) {
        return
      }
      if (message.data?.type == "videoask_submitted") {
        setVideoAskState(true)
      }
      // console.log("got videoask message", message.data)
    });

  })
  return (
    <React.Fragment>
      <div className="news-landing" id="jobform" ref={myRef}>
        <Container>
          <Row>
            <Col md='12'>
              <div className="heading">
                {parse(stepRender(step))}
                {
                  (step === 2) ?
                    <h2>Thanks {formState.firstname}! Now tell us why this job interests you.</h2>
                    : (step === 7) ?
                      <h2>Thanks for your application, {formState.firstname}</h2> : (step === 6) ?
                        <><h2 className="mb-4">Thanks for your application, {formState.firstname}</h2><p>We'd love to get to know you a little better so, send us a short video introducing yourself and telling us a little about who you are.</p><p>This is not a compulsory step and it will not affect the decision whether or not to invite you to an interview.</p></> : <h2>{formTitle[step - 1]}</h2>
                }

              </div>
            </Col>
            <Col md='12'>
              <div className="form-wrapper">
                <Form method="post" >
                  {
                    errorFlag && !minValidation &&
                    <div class="alert alert-danger alert-error">{wordcount > 300 && stepnumber == "2" ? <p>It looks like you've exceeded your word limit. Please keep your message to under 300 words.</p> : <p>Highlighted fields are required | invalid</p>}</div>
                  }
                  {
                    errorFlag && minValidation &&
                    <div class="alert alert-danger alert-error">{wordcount > 300 && stepnumber == "3" ? <p>It looks like you've exceeded your word limit. Please keep your message to under 300 words.</p> : <p>Highlighted fields are required | invalid</p>}</div>
                  }
                  {
                    !fileSupport &&
                    <div class="alert alert-danger alert-error"><p>File are not supported</p></div>
                  }
                  {step === 1 &&
                    <div className="step-1">
                      <div className="select-box form-group">
                        <Form.Label>Title*</Form.Label>

                        <Select
                          value={nameTitleselectedOption}
                          onChange={namehandleChange}
                          options={nameTitleptions}
                          name={'title'}
                          //defaultMenuIsOpen={false}
                          className={`${errClass('title')} select-form-control`}
                          classNamePrefix={`reactSelect2`}
                          styles={styles}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                      </div>
                      <Form.Group className="form-group">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control type="text" name="firstname" value={formState.firstname} className={`${errClass('firstname')}`} placeholder="First name" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control type="text" name="lastname" value={formState.lastname} className={`${errClass('lastname')}`} placeholder="Last name" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>National Insurance number</Form.Label>
                        <Form.Control type="text" name="nationalInsuranceNo" value={formState.nationalInsuranceNo} className={`${errClass('nationalInsuranceNo')}`} placeholder="National insurance number" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control type="text" name="email" value={formState.email} className={`${errClass('email')}`} placeholder="Email address" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Contact Phone Number*</Form.Label>
                        <Form.Control type="number" name="contactPhoneNo" value={formState.contactPhoneNo} className={`${errClass('contactPhoneNo')}`} placeholder="Contact phone number" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Secondary Phone Number</Form.Label>
                        <Form.Control type="number" name="secondaryContactNo" value={formState.secondaryContactNo} className={`${errClass('secondaryContactNo')}`} placeholder="Secondary phone number" onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Your Address*</Form.Label>
                        <LoqateAddress />
                      </Form.Group>
                      <div className="select-box form-group">
                        <Form.Label> Ethnic Origin</Form.Label>
                        <Select
                          value={originselectedOption}
                          onChange={originhandleChange}
                          options={originOptions}
                          name={'ethnicOrigin'}
                          //defaultMenuIsOpen={false}
                          className={`select-form-control`}
                          classNamePrefix={`reactSelect2`}
                          styles={styles}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                      </div>
                      <div className="select-box form-group">
                        <Form.Label>Do you have a full valid driving licence?*</Form.Label>
                        <Select
                          value={drivingLicenseselectedOption}
                          onChange={licensehandleChange}
                          options={drivingLicenseptions}
                          name={'drivingLicense'}
                          //defaultMenuIsOpen={false}
                          className={`${errClass('drivingLicense')} select-form-control`}
                          classNamePrefix={`reactSelect2`}
                          styles={styles}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                      </div>
                    </div>
                  }
                  {step === 2 &&
                    <div className="step-2">
                      <Form.Group className="form-group">
                        <Form.Label>What is your motivation for applying for this position?*</Form.Label>
                        <span className="total"> {charLength('motivation')}/300 words</span>
                        <Form.Control as="textarea" value={formState.motivation} name="motivation" className={`${errClass('motivation')}`} rows={3} onChange={(e) => { handelChange(e) }} />
                      </Form.Group>
                      <label className="custom-checkbox  d-flex align-items-center me-3">I am a recruiter and applying on behalf of a client
                        <input type="checkbox" checked={formState.recruitment_agent} onChange={(e) => {
                          setFormState({
                            ...formState,
                            recruitment_agent: e.target.checked,
                            videoask: e.target.checked
                          });
                        }} />
                        <span className="checkmark"></span>
                      </label>
                      {formState.recruitment_agent &&
                        <div className="recruiter">
                          <Form.Group className="form-group">
                            <Form.Label>Recruiter's Name*</Form.Label>
                            <Form.Control type="text" name="recruiterName" value={formState.recruiterName} className={`${errClass('recruiterName')}`} placeholder="Recruiter's Name" onChange={(e) => { handelChange(e) }} />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Recruiter's Firm*</Form.Label>
                            <Form.Control type="text" name="recruiterFirm" value={formState.recruiterFirm} className={`${errClass('recruiterFirm')}`} placeholder="Recruiter's Firm" onChange={(e) => { handelChange(e) }} />
                          </Form.Group>
                          <div className="select-box form-group">
                            <Form.Label>Have we interviewed the candidate before?*</Form.Label>
                            <Select
                              value={beforeCandidateSelectedOption}
                              onChange={beforeCandidatehandleChange}
                              options={drivingLicenseptions}
                              name={'beforeCandidate'}
                              //defaultMenuIsOpen={false}
                              className={`${errClass('beforeCandidate')} select-form-control`}
                              classNamePrefix={`reactSelect2`}
                              styles={styles}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                            />
                          </div>
                          <Form.Group className="form-group">
                            <Form.Label>Please Upload CV*</Form.Label>
                            <div className={`form-control d-flex flex-column justify-content-center form-control-browsefile custom upload_cv_lable ${errClass('uploadCV')} `} onClick={() => uploadCVRef.current.click()}>
                              <span>{formState.uploadCV ? formState.uploadCV.name : 'Click here to upload CV'}</span></div>
                            <Form.Control
                              type={'file'}
                              onChange={handleAttachment}
                              name="uploadCV"
                              id="upload_cv"
                              className={`d-none`}
                              ref={uploadCVRef}
                              accept=".pdf,.doc,.docx,.pptx,.png,.ppt,.jpeg,.jpg,.xls,.xlsx,.svg"
                            />
                          </Form.Group>
                          <div className="select-box form-group">
                            <Form.Label>Have you advised the candidate that you have submitted their CV?*</Form.Label>
                            <Select
                              value={submittedCVSelectedOption}
                              onChange={submittedCVhandleChange}
                              options={drivingLicenseptions}
                              name={'submittedCV'}
                              //defaultMenuIsOpen={false}
                              className={`${errClass('submittedCV')} select-form-control`}
                              classNamePrefix={`reactSelect2`}
                              styles={styles}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                            />
                          </div>
                        </div>
                      }

                    </div>
                  }
                  {step === 3 &&
                    <div className="step-3">
                      {props.job.vacancy_questions && props.job.vacancy_questions.map((ques, key) => {
                        return (
                          <Form.Group className="form-group" key={key}>
                            <Form.Label>{ques.question_text}*
                              {/* {ques.question_min_words && <span>(min {ques.question_min_words} words):</span>} */}
                            </Form.Label>
                            <span className="total"> {charLength(`vacancy_questions_${key + 1}`)}/300 words</span>
                            <Form.Control as="textarea" value={formState[`vacancy_questions_${key + 1}`]} name={`vacancy_questions_${key + 1}`} className={`${errClass(`vacancy_questions_${key + 1}`)}`} rows={3} onChange={(e) => { handelChange(e) }} />
                          </Form.Group>
                        )
                      })}

                    </div>
                  }
                  {step === 4 &&
                    <div className="step-4">
                      {props.job.vacancy_multichoices && props.job.vacancy_multichoices.map((ques, key) => {
                        return (
                          <Form.Group className="form-group" key={key}>
                            <Form.Label>{ques.multichoice_question_text}*:</Form.Label>
                            {ques.multichoice_answers.map((ans, k) => (
                              <div key={`inline-radio-${key}-${k}`} className="form-text">
                                <Form.Check
                                  inline
                                  label={ans.optionText}
                                  value={ans.optionValue}
                                  className={`${errClass(`vacancy_multichoices_${key + 1}`)}`}
                                  checked={
                                    (formState[`vacancy_multichoices_${key + 1}`] === ans.optionValue) ? true : false
                                  }
                                  name={`vacancy_multichoices_${key + 1}`}
                                  type={'radio'}
                                  onChange={(e) => { handelChange(e) }}
                                  id={`inline-radio-${key}-${k}`}
                                />
                              </div>
                            ))}
                          </Form.Group>
                        )
                      })}
                    </div>
                  }
                  {step === 5 &&
                    <div className="step-5">

                      <Form.Group className="form-group">
                        <Form.Label>Please Upload Your CV*</Form.Label>
                        <div className={`form-control d-flex flex-column justify-content-center form-control-browsefile custom upload_cv_lable ${errClass('uploadCV')} `} onClick={() => uploadCVRef.current.click()}>
                          <span>{formState.uploadCV ? formState.uploadCV.name : 'Click here to upload your CV'}</span></div>
                        <Form.Control
                          type={'file'}
                          onChange={handleAttachment}
                          name="uploadCV"
                          id="upload_cv"
                          className={`d-none`}
                          ref={uploadCVRef}
                          accept=".pdf,.doc,.docx,.pptx,.png,.ppt,.jpeg,.jpg,.xls,.xlsx,.svg"
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Please Upload Your Cover Letter</Form.Label>
                        <div className={`form-control d-flex flex-column justify-content-center form-control-browsefile custom cover_letter_lable`} onClick={() => coverLetterRef.current.click()}>
                          <span>{formState.coverLetter ? formState.coverLetter.name : 'Click here to upload your Cover Letter'}</span></div>
                        <Form.Control
                          type={'file'}
                          onChange={handleAttachment}
                          name="coverLetter"
                          id="cover_letter"
                          className={`d-none`}
                          ref={coverLetterRef}
                          accept=".pdf,.doc,.docx,.pptx,.png,.ppt,.jpeg,.jpg,.xls,.xlsx"
                        />
                      </Form.Group>
                      {/* <Form.Group className="form-group">
                        <Form.Label>Please Upload Your Portfolio</Form.Label>
                        <div className={`form-control d-flex flex-column justify-content-center form-control-browsefile custom portfolio_lable`} onClick={() => portfolioRef.current.click()}>
                          <span>{formState.portfolio ? formState.portfolio.name : 'Click here to upload your CV'}</span></div>
                        <Form.Control
                          type={'file'}
                          onChange={handleAttachment}
                          name="portfolio"
                          id="portfolio"
                          className={`d-none`}
                          ref={portfolioRef}
                          accept=".pdf,.doc,.docx,.pptx,.png,.ppt,.jpeg,.jpg,.xls,.xlsx"
                        />
                      </Form.Group> */}
                      {/* {
                        !videoAsk &&
                        <Button variant="primary" className="video-ask-btn" onClick={(event) => { setVideoAsk(true) }} >Please Upload Your Video</Button>
                      }  */}
                    </div>
                  }
                  {step === 6 &&
                    <div className="step-6">
                      <div className="video-ask">
                        <iframe src={`https://www.videoask.com/${props.job.videoask_video_id}#contact_name=${contactName}&contact_email=${contactEmail}&guid=${enquiryId}`}
                          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                          width="100%"
                          height="530px"

                        ></iframe>
                      </div>
                    </div>
                  }
                  {step === 7 &&
                    <div className="step-7 thankyou">
                      {/* <p>Your application for {props.job.vacancy_type} has successfully been submitted. We will review your details and aim to get back to you within one week. In the meantime, why not browse some of our other vacancies?</p> */}
                      <p>We are focused on helping our customers and staff move forwards on their life’s journey. We are excited to get started working with you. </p>
                    </div>
                  }

                  <div className="area-wrapper">
                    {step < 7 ?
                      <div className="btn-wrapper ">
                        <Button variant="primary" className="d-md-none" type="submit" onClick={(event) => { handleSubmit(event, step) }} >
                          {(step === 6) ? "Submit Application" : "Next step"}

                        </Button>
                        <Button variant="primary" onClick={(event) => {
                          const url = typeof window !== 'undefined' ? window.location.href : ''
                          setErrorFlag(false)
                          setStepNumber("1")
                          if (step !== 1 && step !== 7 && !formState.recruitment_agent) {
                            setStep(step - 1)
                          } else if (step === 6 && formState.recruitment_agent) {
                            setStep(2)
                          }
                          else if (step === 2) {
                            setStep(step - 1)
                            setTimeout(() => {
                              document.getElementById('in_address').value = formState.in_address ? formState.in_address : ''
                            }, 200)

                          }
                          else if (step === 7) {
                            setStep(1)
                            $('html, body').animate({
                              scrollTop: $(".vacancy-details").offset().top
                            }, 100);
                            formState.title = ""
                            setNameTitleselectedOption({ value: null, label: 'Please choose...' })
                            formState.firstname = ""
                            formState.lastname = ""
                            formState.email = ""
                            formState.nationalInsuranceNo = ""
                            formState.contactPhoneNo = ""
                            formState.secondaryContactNo = ""
                            formState.motivation = ""
                            formState.recruitment_agent = false
                            setOriginselectedOption({ value: null, label: 'Start typing...' })
                            setDrivingLicenseselectedOption({ value: null, label: 'Please choose...' })
                          }
                          else if (step !== 2) {
                            navigate(url.replace("/apply/", "").replace("/apply", ""));
                          }
                        }}>
                          Go back
                        </Button>
                        <Button variant="primary" className="d-none d-md-block" type="submit" onClick={(event) => { handleSubmit(event, step) }} >
                          {(step === 6) ? "Submit Application" : "Next step"}

                        </Button>
                      </div>
                      :
                      <div className="main-banner d-flex justify-content-center">
                        <div className="btn-wrap">
                          <Link to={`/vacancy-type`} className="btn btn-secondary last-btn" >
                            <span>Continue searching</span>View All Vacancies
                          </Link>
                        </div>
                      </div>
                    }
                    {(step === 5) &&
                      <span className="bottom-text">† All applications receive equal treatment regardless of sex, race, age, disability, religion, marital
                        status, or sexual orientation. Selection for a post will be based on merit. We would like to assure you that the
                        information you provide will be treated in the strictest confidence and only used only to help us monitor
                        appropriate equal opportunities policies. This information plays no part in our selection process.</span>
                    }
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default JobApply